<template>
	<section>
		<h1>
			<img src="/img/payment/share-space-icon.svg" alt="" /> Espace de partage
		</h1>

		<NoContent :type="'MARKETPLACE'"/>
	</section>
</template>

<script>
import NoContent from '../../../Search/NoContent.vue'

export default {
	components:{
		NoContent
	},
	
}
</script>
